
import { createEvent, deleteEvent, updateEvent, deleteTickets, updateLocations} from '../graphql/mutations';
import { getLocations, listEvents, getEvent, listTickets,  } from "../graphql/queries";
import { API } from "aws-amplify";
import { addEventToLocation, removeEventToLocation, getLocationInfo } from "./locationsHelpers";
import { Storage } from "@aws-amplify/storage"

var QRCode = require('qrcode');

function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(',')[1]);
  var array = [];
  for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

async function createQR(id) {
    var base = process.env.REACT_APP_MAIN_DOMAIN + "event/" + id;
    var tempQR = "";
    QRCode.toDataURL(base, function (err, url) { 
      tempQR = url 
    });
    var QR = dataURItoBlob(tempQR)
    var result, key;
    try {
      result = await Storage.put(id, QR, {
        contentType: 'image/jpeg'
      });
      key = result.key;
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
    return key;
  }

  export async function getEventInfo(SignedIn, id) {
    // Get a specific item
    const oneEvent = await API.graphql({
      query: getEvent,
      variables: { id: id },
      authMode: SignedIn ?  'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
    });
    return oneEvent['data']['getEvent'];
  }
  
export async function getAllEvents(SignedIn, id) {
    const variables = { filter: { locationsID: { eq: id  } } };
    const allEvents = await API.graphql({
        query: listEvents,
        variables: variables,
        authMode: SignedIn ?  'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
    });
    return allEvents['data']['listEvents']['items'];
}

export  async function getLocation(SignedIn, id) {
    // Get a specific item
    const oneLocations = await API.graphql({
        query: getLocations,
        variables: { id: id },
        authMode: SignedIn ?  'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
    });
    return oneLocations['data']['getLocations'];
}


export async function createNewEvent(SignedIn, id, desc, title, enddate, location_title, userIDs, host, eventImage) {
  const newEvent = await API.graphql({
      query: createEvent,
        variables: {
            input: {
              "locationsID": id,
              "description": desc,
              "title": title,
              "endDate": enddate,
              "locationName": location_title,
              "status": "Active",
              "userID": userIDs,
              "host": host,
              "image": eventImage,
              "tickets_sold": 0
            } 
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    var tempID = newEvent['data']['createEvent']['id'];
    var tempQR = await createQR(tempID);
    const updatedEvents = await API.graphql({
      query: updateEvent,
      variables: {
          input: {
            "id": tempID,
            "qr": tempQR
          }
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    await addEventToLocation(SignedIn, id);
    var PDF_key = await createEventPDF(SignedIn, id, title, desc, tempQR, eventImage);
    const updatedEvents2 = await API.graphql({
      query: updateEvent,
      variables: {
          input: {
            "id": tempID,
            "qr": tempQR
          }
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    var to_email = userIDs.toString();
    const email_sent = await sendEmail(PDF_key, location_title, title, to_email);
}

async function createEventPDF(SignedIn, id, title, desc, tempQR, eventImage) {
  var return_location = await getLocationInfo(SignedIn, id);
  var QRKey = "public/" + tempQR;
  var IamgeKey = "public/" + eventImage;
  const res_pdf_data = await fetch(process.env.REACT_APP_PDF_GEN_URL, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type: 'landing',
      data: {
        qrKey: QRKey,
        hoursInputValue: return_location.hours_list,
        eventImage: IamgeKey,
        addressValue: return_location.address,
        phoneNumberValue: return_location.phone,
        topTextValue: desc,
        eventTitleValue: return_location.title,
        locationNameValue: title
      }
    })
  })
  const PDF_res = await res_pdf_data.json();
  var PDF_key = PDF_res.success;
  return PDF_key
}

async function sendEmail(PDF_key, location_title, title, to_email) {
  var subject = "New event added to " + location_title + ': ' + title ;
    const res_data = await fetch(process.env.REACT_APP_EMAIL_SEND_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          "to": to_email,
          "subject": subject,
          "pdfS3Key": PDF_key,
          "eventName": title
      })
    })
    const test = await res_data.json();
    return test;
}

  export async function addTicketToEvent(SignedIn, id) {
    var return_event = await getEventInfo(SignedIn, id);
    var old_total = return_event.tickets_sold;
    var new_total = old_total + 1;
    var details = {
      id: id,
      "tickets_sold": new_total
    };
    
    const updatedEvents = await API.graphql({
        query: updateEvent,
        variables: { input: details },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }
  
  export async function addTicketToLocation(SignedIn, id) {
    var return_event = await getEventInfo(SignedIn, id);
    var loc_id = return_event.locationsID;
    var return_location = await getLocationInfo(SignedIn, loc_id);
    var old_customer_count = return_location.customer_count;
    var old_amount_collected = return_location.amount_collected;
    var new_customer_count = old_customer_count + 1;
    var new_amount_collected = old_amount_collected + 10;
    var details = {
      id: loc_id,
      "customer_count": new_customer_count,
      "amount_collected":new_amount_collected
    };
    
    const updatedEvents = await API.graphql({
        query: updateLocations,
        variables: { input: details },
        authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  }


export async function updateCurrentEvent(id, desc, title, enddate, userIDs, host, image) {

  var details = {
    id: id
  };
 
  if (desc) { details.description = desc; } 
  if (title) { details.title = title; }
  if (enddate) { details.endDate = enddate; } 
  if (host) { details.host = host; } 
  if (userIDs) { details.userID = userIDs; } 
  if (image) { details.image = image; } 
  
  const updatedEvents = await API.graphql({
      query: updateEvent,
      variables: { input: details },
      authMode: "AMAZON_COGNITO_USER_POOLS",
  });
}

export async function finishEventItem(id, desc, title, enddate, userIDs, host, image) {

  var details = {
    id: id
  };
 
  details.status = "Finished";
  
  const updatedEvents = await API.graphql({
      query: updateEvent,
      variables: { input: details },
      authMode: "AMAZON_COGNITO_USER_POOLS",
  });
}

export async function reactivateEventItem(id, desc, title, enddate, userIDs, host, image) {

  var details = {
    id: id
  };
 
  details.status = "Active";
  
  const updatedEvents = await API.graphql({
      query: updateEvent,
      variables: { input: details },
      authMode: "AMAZON_COGNITO_USER_POOLS"
  });
}

  
  export async function deleteEventItem(SignedIn, id) {
    if (window.confirm("Are you sure you want to delete this event?")) {
      const allTickets = await API.graphql({
        query: listTickets,
                authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      var items = allTickets['data']['listTickets']['items'];
      if (items.length > 0) { 
        items.forEach((item) => {
          if (item['eventsID'] === id) {
            const deleteTicket = API.graphql({
                query: deleteTickets,
                variables: {
                    input: {
                        id: item['id'],
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            });
          }
        });
      }
      const deletedEvents = await API.graphql({
          query: deleteEvent,
            variables: {
                input: {
                    id: id
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        });
        removeEventToLocation(SignedIn, deletedEvents['data']['deleteEvent'].locationsID);
    }
  }

